#diagnosis {
    text-align: center;
    font-weight: bolder;
    color: grey;
    font-size: large;
  }
  .progress__container {
    /* width: 600px; */
    height: fit-content;
    z-index: 1;
    margin: 1%;
    position: absolute;
  }
  
  /* #progress__value {
    width: 1%;
    height: 30px;
    background-color: rgba(0, 255, 0, 0.5);
    text-align: center;
    line-height: 30px;
    color: white;
    border-radius: 20px;
    z-index: -2;
  } */
  
  .progress__header {
    text-align: center;
    z-index: 1;
    font-weight: bolder;
    font-family: Arial, Helvetica, sans-serif;
    font-size: large;
    width: fit-content;
    height: fit-content;
  }
  .app_container {
    display: flex;
    place-content: center center;
  }
  .body {
    margin: 0;
    /* box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5) */
  }
  .inst__container {
    grid-row: 1;
    grid-column: 1;
    /* position: absolute; */
    z-index: 1;
  }
  .skip__container {
    grid-row: 1;
    grid-column: 1;
    z-index: 2;
    place-self: start end;
    margin: 15px;
  }
  
  .info__container {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .info__message {
    font-weight: 800;
    font-size: larger;
  }
  .webcam__container {
    display: grid;
    /* grid-auto-flow: dense; */
    /* position: relative; */
    /* height: max-content; */
    /* width: max-content; */
    place-content: center center;
    place-items: center center;
    /* box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5) */
    /* grid-auto-rows: minmax(100px,auto);  */
  }
  .webcam__video {
    /* display: flex; */
    grid-row: 1;
    grid-column: 1;
    z-index: -2;
    /* margin-left: auto;
      margin-right: auto; */
  }
  .button__container {
    grid-row: 1;
    grid-column: 1;
    z-index: 0;
    place-self: end start;
    margin: 15px;
    /* position:absolute;
      bottom: 8%;
      left: 5%; */
  }
  .start__button {
    background-color: #4caf50; /* Green */
    border: none;
    border-radius: 20px;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 4px 2px;
    cursor: pointer;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .start__button:hover {
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
      0 17px 50px 0 rgba(0, 0, 0, 0.19);
  }
  .body > h2 {
    text-align: center;
  }
  .fps {
    text-align: center;
  }
  .version {
    text-align: center;
    /* position: absolute; */
  }
  .message {
    text-align: justify;
  }
  .instruction__box {
    position: absolute;
    place-self: end center;
    grid-row: 1;
    grid-column: 2;
    margin-bottom: 35px;
    margin-left: 120px;
    color: red;
  }
  .instruction__box p {
    font-weight: 800;
  }
  